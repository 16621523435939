var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("Listado de cierres")]),_c('v-card-text',[_c('div',[_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"blue darken-1","dark":"","right":"","fab":"","small":""},on:{"click":function($event){_vm.dialog = true}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"material-icons"},[_vm._v("add")])])]}}])},[_c('span',[_vm._v("Agregar Cierre")])])],1)],1)],1)])],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header card-header-primary card-header-icon"},[_vm._m(0),_c('h4',{staticClass:"card-title"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_vm._v(" Listado de Cierres ")]),_c('div',{staticClass:"col-md-6"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Escriba Para buscar Cierre","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"toolbar"}),_c('div',{staticClass:"material-datatables"},[_c('v-data-table',{staticClass:"elevation-1 mx-0",attrs:{"headers":_vm.cierresListHeaders,"items":_vm.cierresListBody,"label":"Escriba Para buscar Cierre","no-data-text":"Listado de Cierres","fixed-header":"","search":_vm.search,"header-props":{ sortIcon: 'arrow_upward' },"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'navigate_befores',
            nextIcon: 'navigate_next'
            //'items-per-page-options':[5,10,20],
          }},scopedSlots:_vm._u([{key:"item.month",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.months[item.month - 1].nombre))])]}},{key:"item.inicio",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.inicio)))])]}},{key:"item.fin",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.fin)))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",staticStyle:{"cursor":"pointer","color":"orange"},attrs:{"medium":""},on:{"click":function($event){return _vm.editCierre(item, false)}}},on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',[_vm._v("Editar cierre")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticStyle:{"cursor":"pointer","color":"red"},attrs:{"medium":""},on:{"click":function($event){return _vm.deleteCierre(item)}}},on),[_vm._v(" delete ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar cierre")])])]}}],null,true)})],1)])]),_c('v-dialog',{attrs:{"width":"800px"},on:{"click:outside":_vm.closeModal},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"blue darken-1"},[_vm._v(_vm._s(_vm.title))]),_c('v-card-text',[_c('v-row',{staticClass:"mx-2"},[_c('v-col',{staticClass:"align-center justify-space-between",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-factory","items":_vm.years,"item-text":"nombre","item-value":"id","label":"Año","color":"blue darken-3"},model:{value:(_vm.cierre.year),callback:function ($$v) {_vm.$set(_vm.cierre, "year", $$v)},expression:"cierre.year"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"prepend-icon":"mdi-factory","items":_vm.months,"item-text":"nombre","item-value":"id","label":"Mes","color":"blue darken-3"},on:{"change":_vm.setInicioTermino},model:{value:(_vm.cierre.month),callback:function ($$v) {_vm.$set(_vm.cierre, "month", $$v)},expression:"cierre.month"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de Inicio","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.inicio_formated),callback:function ($$v) {_vm.inicio_formated=$$v},expression:"inicio_formated"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"es-cl","prev-icon":"chevron_left","next-icon":"chevron_right"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.cierre.inicio),callback:function ($$v) {_vm.$set(_vm.cierre, "inicio", $$v)},expression:"cierre.inicio"}})],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de Termino","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.termino_formated),callback:function ($$v) {_vm.termino_formated=$$v},expression:"termino_formated"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"es-cl","prev-icon":"chevron_left","next-icon":"chevron_right"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.cierre.fin),callback:function ($$v) {_vm.$set(_vm.cierre, "fin", $$v)},expression:"cierre.fin"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.closeModal}},[_vm._v("Cerrar")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.saveCierre}},[_vm._v("Grabar")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":5000,"color":_vm.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Cerrar ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.validation_message)+" ")])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-icon",staticStyle:{"background":"#1565C0 !important"}},[_c('i',{staticClass:"material-icons"},[_vm._v("list")])])}]

export { render, staticRenderFns }